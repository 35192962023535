import React, { useEffect, useState, Fragment } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { FiMenu } from 'react-icons/fi';
import { Link as ScrollLink, Events } from 'react-scroll'
import styled from 'styled-components'
import { map } from 'styled-components-breakpoint';

import '../styles/custom.tachyons.css';

const AnchorStyled = styled(ScrollLink)`
  cursor: pointer;
`

const MobileMenuButton = styled.button`
  ${map({xs: 'block', nv: 'none'}, display => `display: ${display};`)}
`

const MultiLink = (props) => {
  const internal = /^\/(?!\/)/.test(props.to);
  if (internal) {
    return <Link to={props.to} className={props.className}>{props.children}</Link>
  } else {
    return (
      <AnchorStyled
        spy={true}
        smooth={true}
        offset={-70}
        duration= {500}
        to={props.to}
        className={props.className}
      >
        {props.children}
      </AnchorStyled>
    )
  }
}

const SliderMenu = (props) => {
  // Prevents a flash of visible menu items when the entrance is triggered
  let extraClasses;
  if (props.active === null) {
    extraClasses = " dn";
  } else {
    extraClasses = (props.active ? " fadeIn" : " fadeOut");
  }
  return (
    <div
      className={
        "flex flex-column justify-center items-center bg-washed-blue fixed top z-max w-100 ease" + (props.active ? " vh-93" : " h0")
      }>
      {props.slugPath === '/' ? props.extraLinks.map(({itemUrl, itemTexto}) => (
        <Fragment key={itemUrl}>
          {itemUrl === 'blog' ? (<Link to={itemUrl} className={"sans-serif ttu mid-gray f5 no-underline menu__item pv3" + extraClasses}>{itemTexto}</Link>) : (
            <MultiLink to={itemUrl} className={"sans-serif ttu mid-gray f5 no-underline menu__item pv3" + extraClasses}>{itemTexto}</MultiLink>
          )}
        </Fragment>
      )) : props.extraLinks.map(({itemUrl, itemTexto}) => (
        <Fragment key={itemUrl}>
          {itemUrl === 'blog' ? (<Link to={`/${itemUrl}`} className={"sans-serif ttu mid-gray f5 no-underline menu__item pv3" + extraClasses}>{itemTexto}</Link>) : (
            <Link to={`/#${itemUrl}`} className={"sans-serif ttu mid-gray f5 no-underline menu__item pv3" + extraClasses}>{itemTexto}</Link>
          )}
        </Fragment>
      ))}
    </div>
  )
}


export default function Navbar() {
  const [menuToggle, setMenuToggle] = useState(false)
  const [slugPath, setSlugPath] = useState('/')
  const toggleMenu = () => {
    setMenuToggle(!menuToggle);
  };

  useEffect(() => {
    Events.scrollEvent.register('end', () => {
      setMenuToggle(false);
    })

    setSlugPath(window.location.pathname)
  }, [])

  return (
    <StaticQuery
      query={graphql`
      query {
        wp {
          allSettings {
            generalSettingsTitle
          }
        }
        wpPage(isFrontPage: {eq: true}, status: {eq: "publish"}) {
          acfPage {
            menuPrincipal {
              itemUrl
              itemTexto
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <div
          className="bg-white flex w-100 vh-7 pv3 flex justify-between items-center top-0 z-999 bb b--light-gray"
          style={{position: "sticky"}}>
          <div className="w-100 mw8 flex justify-between justify-around-l items-center ph4 pa2-ns">
            <MobileMenuButton
              className="ttu tracked dark-gray f4 no-underline bn bg-transparent pointer"
              onClick={toggleMenu}>
              <FiMenu />
            </MobileMenuButton>
            <Link to="/" className="display ttu tracked dark-gray f4 no-underline">{data.wp.allSettings.generalSettingsTitle}</Link>
            {slugPath === '/' ? data.wpPage.acfPage.menuPrincipal.map(({itemUrl, itemTexto}) => (
              <Fragment key={itemUrl}>
                {itemUrl === 'blog' ? (<Link to={itemUrl} className="sans-serif ttu mid-gray f5 no-underline dn dib-l">{itemTexto}</Link>) : (
                  <MultiLink to={itemUrl} className="sans-serif ttu mid-gray f5 no-underline dn dib-l">{itemTexto}</MultiLink>
                )}
              </Fragment>
            )) : data.wpPage.acfPage.menuPrincipal.map(({itemUrl, itemTexto}) => (
              <Fragment key={itemUrl}>
                {itemUrl === 'blog' ? (<Link to={`/${itemUrl}`} className="sans-serif ttu mid-gray f5 no-underline dn dib-l">{itemTexto}</Link>) : (
                  <Link to={`/#${itemUrl}`} className="sans-serif ttu mid-gray f5 no-underline dn dib-l">{itemTexto}</Link>
                )}
              </Fragment>
            ))}
          </div>
        </div>
        <SliderMenu
          active={menuToggle}
          extraLinks={data.wpPage.acfPage.menuPrincipal}
          siteTitle={data.wp.allSettings.generalSettingsTitle}
          slugPath={slugPath}
        />
      </>
    )} />
  )
}
