import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaPinterest,
  FaPhone,
  FaLocationArrow,
  FaMailBulk,
  FaLinkedin,
} from 'react-icons/fa';
import 'tachyons';

import styled from 'styled-components'

const AnchorStyled = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: "Karla", sans-serif !important;
  text-transform: uppercase;
  svg {
    margin: 5px;
  }
`

export default () => (
  <StaticQuery
    query={graphql`
      query {
        wp {
          allSettings {
            generalSettingsTitle
          }
        }
        wpPage(isFrontPage: {eq: true}, status: {eq: "publish"}) {
          acfPage {
            redesSocialesTwitter
            redesSocialesFacebook
            redesSocialesInstagram
            redesSocialesPinterest
            redesSocialesLinkedin
            informacionDeContactoTelefono
            informacionDeContactoUbicacion
            informacionDeContactoUbicacionDescripcion
            informacionDeContactoCorreoElectronico
          }
        }
      }
    `}
    render={data => (
      <footer className="pa2 bg-dark-gray near-white pv5">
        <div className="flex flex-wrap justify-around w-100 mw9 center mb5">
          <div className="w-100 mw5 mb4">
            <span className="display f2">{data.wp.allSettings.generalSettingsTitle}</span>
            <hr />
              <div className="w-100 flex items-center pv2">
              {data.wpPage.acfPage.redesSocialesFacebook && (
                <a
                  className="near-white"
                  href={data.wpPage.acfPage.redesSocialesFacebook}
                >
                  <FaFacebookF />
                </a>
              )}

              {data.wpPage.acfPage.redesSocialesTwitter && (
                <a
                  className="near-white ml2"
                  href={data.wpPage.acfPage.redesSocialesTwitter}
                >
                  <FaTwitter />
                </a>
              )}

              {data.wpPage.acfPage.redesSocialesInstagram && (
                <a className="near-white ml2" href={data.wpPage.acfPage.redesSocialesInstagram}>
                  <FaInstagram />
                </a>
              )}

              {data.wpPage.acfPage.redesSocialesPinterest && (
                <a className="near-white ml2" href={data.wpPage.acfPage.redesSocialesPinterest}>
                  <FaPinterest />
                </a>
              )}

              {data.wpPage.acfPage.redesSocialesLinkedin && (
                <a className="near-white ml2" href={data.wpPage.acfPage.redesSocialesLinkedin}>
                  <FaLinkedin />
                </a>
              )}
            </div>
          </div>
          <div className="flex flex-column">
            {data.wpPage.acfPage.informacionDeContactoTelefono && (
              <AnchorStyled className="near-white" href={`tel:${data.wpPage.acfPage.informacionDeContactoTelefono}`}>
                <FaPhone />
                {data.wpPage.acfPage.informacionDeContactoTelefono}
              </AnchorStyled>
            )}
            {data.wpPage.acfPage.informacionDeContactoUbicacion && (
              <AnchorStyled className="near-white" href={data.wpPage.acfPage.informacionDeContactoUbicacion}>
                <FaLocationArrow />
                {data.wpPage.acfPage.informacionDeContactoUbicacionDescripcion}
              </AnchorStyled>
            )}
            {data.wpPage.acfPage.informacionDeContactoCorreoElectronico && (
              <AnchorStyled className="near-white" href={`mailto:${data.wpPage.acfPage.informacionDeContactoCorreoElectronico}`}>
                <FaMailBulk />
                {data.wpPage.acfPage.informacionDeContactoCorreoElectronico}
              </AnchorStyled>
            )}
          </div>
        </div>
        <div className="w-100 mw9 silver center sans-serif f6">
          <p>{data.wp.allSettings.generalSettingsTitle} | Todos los derechos reservados {new Date().getFullYear().toString()}.</p>
        </div>
      </footer>
    )} />
)
