import React from 'react';
import Helmet from 'react-helmet';
import {StaticQuery, graphql} from 'gatsby';


export default () => (
  <StaticQuery
    query={graphql`
      query {
        wp {
          allSettings {
            generalSettingsTitle
            generalSettingsDescription
          }
        }
      }  
    `}
    render={data => (
      <Helmet>
        <title>{`${data.wp.allSettings.generalSettingsTitle} - ${data.wp.allSettings.generalSettingsDescription}`}</title>
        <meta name="description" content={data.wp.allSettings.generalSettingsDescription} />
      </Helmet>
    )} />
)
